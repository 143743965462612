<template>
    <div class="project">
        <h1>Cinder-View</h1>
        <div class="topInfo">
            <p>
                2015 - 2019<br />
                <a href="https://github.com/richardeakin/Cinder-View">Github Repository</a>
            </p>
        </div>
        <div class="content">
            <b-img class="media fade-in" src="@/assets/projects/cinder-view/scroll-tests.png" fluid></b-img>
            <p>
                Cinder-View is a UI toolkit for the Cinder C++ framework, developed out of the needs of real-world
                production applications largely within the interactive installation realm. At the core, it is a 2d scene
                graph that is intended to be manipulated by multiple simultaneous users, many times on massively large
                canvases. The toolkit aims to focus on a high quality render for user interfaces (ex. pre-multiplied
                alpha compositing), inspired by Apple’s UIView although meant to be used in cross-platform, large
                display scale scenarios.
            </p>
            <p>
                The core principle that lead the design of this toolkit was to ease the development of modern user
                interfaces similar to what you find on the web or mobile platforms like iOS (things like layout,
                animation, transitions), while at the same time giving space for ustom graphics techniques or special
                hardware needs. See the shortlist of projects using Cinder-View towards the end of this post for
                concrete examples.
            </p>
            <p>
                Cinder-View is easily integrated into cinder projects as a <a href="https://libcinder.org/docs/guides/cinder-blocks/index.html">cinderblock</a>
                using <a href="https://libcinder.org/docs/guides/tinderbox/index.html">Tinderbox</a>, or by using the
                provided cmake configuration.
            </p>
            <h2>Toolkit Features</h2>
            <ul>
                <li>
                    <b>Multi-touch</b> - direct support for multi touch / multi user scenarios (the original reason this library was created).
                </li>
                <li>
                    <b>Library of Controls (‘widgets’)</b> - Button, ImageView, HSlider, VSlider, Label, Checkbox, Numberbox, TextField, all standard stuff for ui toolkits.
                </li>
                    <b>ScrollViews</b> - building blocks for scrollable content, both free moving and paging. ScrollViews can be nested, if parent ScrollViews give an opportunity for the touch to be ‘stolen’.
                <li>
                    <b>Compositing</b> - adjusting a View’s alpha will (by default) cause it to be rendered to a framebuffer, then composited with its parent. This can be specified as either standard or pre-multiplied alpha blending.
                </li>
                <li>
                    <b>Filters</b> - can be added to a View to provide post effects. Includes basic blur and dropshadow filters.
                </li>
                <li>
                    <b>Layout</b> - each View can use a Layout object to set the bounds of it’s children based on some simple ruleset. Currently includes: BoxLayout, LinearLayout, VerticalLayout, HorizontalLayout.
                </li>
                <li>
                    <b>Responder Chain</b> - facilitates tex entry by tabbing between widgets.
                </li>
                <li>
                    <b>Gestures</b> - higher level touch heuristics, includes (multi) tap and swipe gesture trackers.
                </li>
            </ul>            
            <h2>Projects</h2>
            Here are a few projects using Cinder-View:
            <ul>
            <li>
                <b><a href="/projects/centerstage">Centerstage</a></b> - The starting place for me in UI toolkit design, changed a lot since then but this is the roots.
            </li>
            <li>
                <b><a href="/projects/connections-wall">Connections Wall at Northwestern University</a></b> - Nine HD, multi-touch displays with a combination of physics simulation and interactive UI content.
            </li>
            <li>
                <b><a href="https://www.potiondesign.com/project/walter_athletic_center/">Northwestern University Athletic Center</a></b> - Four separate machines, two are 4k touch and two are large vertical scrollers.
            </li>
            <li>
                <b><a href="https://rarevolume.com/work/theshed/">The Shed at Hudson Yards</a></b> - Massive canvases (multiple 4k windows) rendered with real-time content from the current event schedule.
            </li>
            <li><b><a href="https://github.com/richardeakin/mason/blob/master/src/mason/Hud.h">mason::Hud</a></b></li> - Imediate Mode layer built on top of Cinder-View that I use for personal projects and other dev work, see image below.
            </ul>
            <br>
            <b-img class="media fade-in" src="@/assets/projects/cinder-view/hud-musevis.png" fluid></b-img>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";
</style>